import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import createBrowserHistory from 'history/createBrowserHistory';
import { Provider } from 'react-redux';
import { Snackbar, Grid } from '@mui/material';
import { Wifi } from '@mui/icons-material';
import 'babel-polyfill';
import './index.module.scss';
import Routes from './constants/Routes';
import store from './constants/Store';
import AppOffline from './components/SharedComponents/Offline/Offline';

const history = createBrowserHistory();

type State = {
    // isOffline: boolean,
}

class App extends Component<{}, State> {
    constructor() {
        super();
        this.state = {};
    }

    // Adding UserName in Datadog after Reload
    componentDidMount() {
        if (window.FLEET_CONFIG.DD_RUM_ENABLED && window.DD_RUM) {
            const storedUsername = localStorage.getItem('username');
            const currentUser = window.DD_RUM.getUser();

            if (storedUsername && (!currentUser || Object.keys(currentUser).length === 0)) {
                window.DD_RUM.setUser({ name: storedUsername });
            }
        }
    }

    render() {
        const isOffline = true;
        return (
            <React.Fragment>
                <Provider store={store}>
                    <Router>
                        <Routes history={history} />
                    </Router>
                </Provider>
                <AppOffline>
                    <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        open={isOffline}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={
                            <Grid container style={{ minWidth: 380 }}>
                                <Grid item xs={1}>
                                    <Wifi color={(isOffline) ? 'inherit' : 'primary'} />
                                </Grid>
                                <Grid item xs={11}>
                                    <span>
                                        You are offline, Please check your network connection
                                    </span>
                                </Grid>
                            </Grid>
                        }
                    />
                </AppOffline>
            </React.Fragment>
        );
    }
}

export default App;
