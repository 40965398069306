/* @flow */
import React from 'react';
import withStyles from '@mui/styles/withStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import {
    convertDateTime,
    convertSpeedAsperUserSetting,
    getTimeFormatAsperUserSettting,
    assetDataFilterBasedOnDeviceFeature,
    getDistanceString,
    getTemperatureUnitString,
    isBoolean,
} from '../../helper-classes/utility-functions';
import {
    getInputStatusCell,
    getOilCell,
    getSeatBeltCell,
    getTirePressureCell,
    getFuelCell,
    getTemperatureCell,
    getBatteryStatusIcon,
    getSignalStrengthIcon,
    getFridgeStatusCell,
    getTrackerBatteryStatus,
} from '../../helper-classes/utility-react-functions';

type Props = {
    classes : Object,
    assetStatus: Object,
    assetDetails: Object,
    assetFeatures: Object
}
const styles = theme => ({
    root: {
        width: '100%',
        overflow: 'auto',
        height: 'calc(100vh - 435px)',
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        paddingBottom: 30,
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    primary: {
        fontSize: '16px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        letterSpacing: '-0.1px',
        textTransform: 'capitalize',
        color: '#4d4d4e',
    },
    primaryText: {
        fontSize: '16px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        letterSpacing: '-0.1px',
        color: '#4d4d4e',
    },
    secondary: {
        fontSize: '13px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        letterSpacing: '-0.1px',
        color: '#999999',
    },
    ListSubheader: {
        opacity: '0.87',
        fontSize: '16px',
        fontWeight: '600',
        fontStyle: 'normal',
        fontStretch: 'normal',
        letterSpacing: '-0.4px',
        color: '#000000',
        paddingLeft: 10,
        lineHeight: '25px',
        paddingTop: 18,
    },
    ListItemText: {
        paddingTop: 10,
        paddingLeft: 14,
        '& .MuiListItemText-root': {
            margin: '2px 0px',
        },
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
});
function DetailAttributes(props : Props) {
    const { classes, assetFeatures } = props;
    const isSticky = false;
    const assetDetailsData = [
        {
            key: 'Group',
            value: props.assetDetails.assetGroupName,
            show: props.assetDetails.assetGroupName !== '',
        },
        {
            key: 'Tags',
            value: props.assetDetails.tags ? props.assetDetails.tags.join(', ') : '',
            show: props.assetDetails.tags !== '',
        },
        {
            key: 'Driver',
            value: props.assetDetails.driver,
            show: props.assetDetails.driver !== '',
        },
        {
            key: 'Asset Type',
            value: props.assetDetails.typeName,
            show: props.assetDetails.typeName !== '',
        },
        {
            key: 'Engine Hours',
            value: props.assetDetails.engineHours,
            show: props.assetDetails.engineHours !== '',
        },
        {
            key: 'Battery Status',
            value: props.assetDetails.batteryStatus,
            show: props.assetDetails.batteryStatus !== '',
        },
        {
            key: 'Tracker Battery',
            value: getTrackerBatteryStatus({
                deviceBatteryStatus: props.assetDetails.data.deviceBatteryStatus,
                intVolt: props.assetDetails.intVolt,
            }),
            show: props.assetDetails.intVolt !== '',
        },
        {
            key: 'Asset Battery',
            value: getBatteryStatusIcon(props.assetDetails.extVolt),
            show: true,
        },
        {
            key: 'Cargo Loaded',
            value: (() => {
                const { cargoLoaded } = props.assetDetails.data || {};
                let cargoLoadedValue = '';
                if (isBoolean(cargoLoaded)) {
                    cargoLoadedValue = cargoLoaded ? 'Loaded' : 'Unloaded';
                }
                return (<span>{cargoLoadedValue}</span>);
            })(),
            show: true,
        },
        {
            key: 'Tractor Power',
            value: (() => {
                const { powerConnected } = props.assetDetails.data || {};
                let powerConnectedValue = '';
                if (isBoolean(powerConnected)) {
                    powerConnectedValue = powerConnected ? 'Connected' : 'Disconnected';
                }
                return (<span>{powerConnectedValue}</span>);
            })(),
            show: true,
        },
        {
            key: 'Signal Strength',
            value: getSignalStrengthIcon(props.assetDetails.data.signalStrengthStatus),
            show: true,
        },
        {
            key: 'Latitude',
            value: props.assetDetails.latitude,
            show: props.assetDetails.latitude !== '',
        },
        {
            key: 'Longitude',
            value: props.assetDetails.longitude,
            show: props.assetDetails.longitude !== '',
        },
        {
            key: 'Make',
            value: props.assetDetails.make,
            show: props.assetDetails.make !== '',
        },
        {
            key: 'Model',
            value: props.assetDetails.model,
            show: props.assetDetails.model !== '',
        },
        {
            key: 'Vin',
            value: props.assetDetails.vin,
            show: props.assetDetails.vin !== '',
        },
        {
            key: 'Year',
            value: props.assetDetails.year,
            show: props.assetDetails.year !== '',
        },
        {
            key: `Odometer / Total Mileage (${getDistanceString()})`,
            value: props.assetDetails.odometer,
            show: props.assetDetails.odometer !== '',
        },
        {
            key: `Temperature (${getTemperatureUnitString()})`,
            value: getTemperatureCell(props.assetDetails.data.cargoTemperature),
            show: props.assetDetails.data.cargoTemperature && assetDataFilterBasedOnDeviceFeature('temperature'),
        },
        {
            key: 'Input Status',
            value: getInputStatusCell(props.assetDetails.data),
            show: getInputStatusCell(props.assetDetails.data) !== '',
        },
        {
            key: 'Oil Life',
            value: getOilCell(props.assetDetails.data, {}),
            show: getOilCell(props.assetDetails.data, {}) !== '' && assetDataFilterBasedOnDeviceFeature('oilLifeRemaining', assetFeatures),
        },
        {
            key: 'Seat Belt Status',
            value: getSeatBeltCell(props.assetDetails.data, {}),
            show: getSeatBeltCell(props.assetDetails.data, {}) !== '' && assetDataFilterBasedOnDeviceFeature('seatBeltStatus', assetFeatures),
        },
        {
            key: 'Tire Pressure',
            value: getTirePressureCell(props.assetDetails.data, {}),
            show: getTirePressureCell(props.assetDetails.data, {}) !== '' && assetDataFilterBasedOnDeviceFeature('tirePressure', assetFeatures),
        },
        {
            key: 'Fuel Level',
            value: getFuelCell(props.assetDetails.data, {}),
            show: getFuelCell(props.assetDetails.data, {}) !== '' && assetDataFilterBasedOnDeviceFeature('fuelLevelStatus', assetFeatures),
        },
        {
            key: 'Fridge Status',
            value: getFridgeStatusCell(props.assetDetails.data),
            show: getFridgeStatusCell(props.assetDetails.data) !== '' && assetDataFilterBasedOnDeviceFeature('fridgeStatus', assetFeatures),
        },
    ];

    return (
        <List id="attributes-list" className={classes.root} subheader={<li />} sx={{ maxHeight: 'unset' }}>
            <li key="attributes" className={classes.listSection}>
                <ul className={classes.ul}>
                    <ListSubheader id="header-asset-attributes" className={classes.ListSubheader} disableSticky={isSticky}>
                        Asset Status
                    </ListSubheader>
                    <ListItem key="asset-heading" className={classes.ListItemText}>
                        <ListItemText
                            primary={props.assetStatus.heading}
                            id="asset-heading"
                            secondary="Heading"
                            classes={{
                                primary: classes.primary,
                                secondary: classes.secondary,
                            }}
                        />
                    </ListItem>
                    <ListItem key="asset-speed" className={classes.ListItemText}>
                        <ListItemText
                            primary={(props.assetStatus.speed !== '') &&
                            convertSpeedAsperUserSetting(props.assetStatus.speed)
                            }
                            id="asset-speed"
                            secondary="Speed"
                            classes={{
                                primary: classes.primaryText,
                                secondary: classes.secondary,
                            }}
                        />
                    </ListItem>
                    <ListItem key="asset-lastEventDate" className={classes.ListItemText}>
                        <ListItemText
                            primary={(props.assetStatus.lastEventDate !== '') &&
                            convertDateTime(props.assetStatus.lastEventDate, (getTimeFormatAsperUserSettting() === '12h') ? 'MMM DD, YYYY @ h:mm A' : 'MMM DD, YYYY @ H:mm')
                            }
                            id="asset-lastEventDate"
                            secondary="Last Report Date"
                            classes={{
                                primary: classes.primary,
                                secondary: classes.secondary,
                            }}
                        />
                    </ListItem>
                </ul>
            </li>
            <li key="custom" className={classes.listSection}>
                <ul className={classes.ul}>
                    <ListSubheader className={classes.ListSubheader}>
                        Asset Details
                    </ListSubheader>
                    {assetDetailsData.map((d) => {
                        if (d.show) {
                            return (
                                <ListItem key={d.key} className={classes.ListItemText}>
                                    <ListItemText
                                        primary={d.value}
                                        secondary={d.key}
                                        classes={{
                                            primary: classes.primary,
                                            secondary: classes.secondary,
                                        }}
                                    />
                                </ListItem>
                            );
                        }
                        return '';
                    })}
                </ul>
            </li>
        </List>
    );
}

export default withStyles(styles)(DetailAttributes);
